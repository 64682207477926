import React from 'react';
//Importamos otros componentes
import Card from './Card.jsx'

const Cards = (props) => {
    let { allFoods } = props;
    // console.log(allFoods)
    return(
        <div className='Cards'>
            {
                //Si existe las recetas, renderizamos el componente card
                allFoods && allFoods.map(( food, i )=>(
                    <Card
                        key = {i}
                        id = {food.id}
                        name = {food.name}
                        image = {food.image}
                        diets = {food.diets}
                        healthScore = {food.healthScore}
                    />
                ))
            }
        </div>
    )
}
export default Cards;