import React,{ useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../redux/authActions.js'
import { validateLogin}  from './validation.js'



const Login = () => {

    const dispatch = useDispatch();
    const responseAuth = useSelector(state=>state.authReducer.response);
    const accessAuth = useSelector(state=>state.authReducer.access);
    const navigate = useNavigate();

    useEffect(()=>{
        console.log(responseAuth)
        if(responseAuth.message){
            
            navigate('/home');
        }else if(responseAuth.error){
            alert(responseAuth.error)
        }
    },[responseAuth,navigate])

    const [ input , setInput ] = useState({
        username:'',
        password:'',
    });

    const [error, setError ] = useState({
        username:'',
        password:'',
    })


    const handleInputChange = (e) => {
        // console.log(e.target.value)
        setInput({
            ...input,
            [e.target.name]:e.target.value
        })
        setError(validateLogin({...input,
            [e.target.name]:e.target.value,
           }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(loginUser(input))  
    }
    
    //Si el estado global esta en "false" no tiene autorizacion para ingresar
    useEffect(() => {
        !accessAuth && navigate('/');
      }, [accessAuth,navigate]);


    return(
        <div className="Welcome">
            {/* {(responseAuth.access) &&  <Nav logout={logout} />} */}
            <div className="Login LoginUser">
                <h1>Henry Foods</h1>
                <div className="LoginForm">
                    
                    <form onSubmit={handleSubmit}>
                        <div className="form__input">
                            <label htmlFor="username">Username: </label>
                            <input type="text" name="username" id="username" onChange={handleInputChange}/>
                        </div>
                        {error.username && <p className="danger">{error.username}</p>}
                            
                        <div className="form__input">
                            <label htmlFor="password">Password: </label>
                            <input type="password" name="password" id="password" onChange={handleInputChange}/>
                        </div>
                        {error.password && <p className="danger">{error.password}</p>}
                        <button type="submit" className='btn btnLarge btnRed btnBlock'>SIGN IN</button>
                    </form>
                   
                    
                </div>
               
            </div>
        </div>
    )
}
export default Login;