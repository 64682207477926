import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Logo from '../imgs/logo-food.png'
import { logoutUser } from '../redux/authActions';
import { useNavigate } from 'react-router-dom';

const Nav = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = (e) => {
        navigate('/');
        dispatch(logoutUser(false))  
        console.log('false')
    }
   
    return(
        <div className='Nav'>
            <div className="Logo">
                <img src={Logo} alt="Food" />
                <h1 className='Logo__title'>HENRY FOOD</h1>
            </div>
            <div className='Menu'>
                <NavLink to="/home" className="btn ">Home</NavLink>
                <NavLink to="/about" className="btn ">About</NavLink>
                <NavLink to="/" className="btn " onClick={logout}>Logout</NavLink>
                <NavLink to="/create" className="btn btnMedium btnGreen">New Food</NavLink>
            </div>
        </div>
    )
}
export default Nav;