import React from 'react';
import { Routes, Route } from 'react-router-dom';
//Importando componentes
// import Welcome from './components/Welcome.jsx';
import Login from './components/Login.jsx';
import Home from './components/Home.jsx';
import Detail from './components/Detail.jsx';
import Form from './components/Form.jsx';
import About from './components/About.jsx';
//importando recursos
import './css/styles.css'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login/>}/>
        <Route path="/home" element={<Home/>}/>
        <Route path="/recipes/:detailId" element={<Detail/>}/>
        <Route path="/create" element={<Form/>}/>
        <Route path="/about" element={<About/>}/>
      </Routes>
    </div>
  );
}

export default App;
