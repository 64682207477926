
//variable de entorno para definir la ruta principal del client
const  BASE_URL  = process.env.REACT_APP_BASE_URL;

export const GET_ALL_FOODS = 'GET_ALL_FOODS';
export const FILTER_FOODS_BY_DIETS = 'FILTER_FOODS_BY_DIETS';
export const ORDER_FOODS = 'ORDER_FOODS';
export const SEARCH_FOOD_BY_NAME = 'SEARCH_FOOD_BY_NAME';
export const SELECT_PAGE = 'SELECT_PAGE';
export const GET_FOOD_BY_ID = 'GET_FOOD_BY_ID';
export const CLEAR_STATE = 'CLEAR_STATE';
export const CREATE_FOOD = 'CREATE_FOOD';
export const GET_DIETS = 'GET_DIETS';
export const ORDER_HEALTHSCORE = 'ORDER_HEALTHSCORE';

// const BASE_URL = 'http://localhost:3001/';

export const getAllFoods = () => {
    return async ( dispatch ) => {
        let response = await fetch(`${BASE_URL}recipes`)
        let data = await response.json();
        return dispatch({
            type: GET_ALL_FOODS,
            payload: data
        })
    }
}

export const getFoodById = (id) => {
    return async ( dispatch ) => {
        let response = await fetch(`${BASE_URL}recipes/${id}`)
        let data = await response.json();
        return dispatch({
            type: GET_FOOD_BY_ID,
            payload: data
        })
    }
}
export const createFood = (payload) => {
    return async (dispatch) => {
        // let myHeaders = new Headers({
        //     'Content-Type': 'application/json'
        // });
        let myInit = {
            method:'POST',
            // body: JSON.stringify(payload),
            body: payload
            // headers: myHeaders
        }
        let response = await fetch(`${BASE_URL}recipes`, myInit);
        let data = await response.json()
        if(data.error)return window.alert(data.error);
        else window.alert(data.message)

        return (dispatch({
            type: CREATE_FOOD,
            payload: payload
        }))
    }

}
export const getDiets = () => {
    return async (dispatch) =>{
        let response = await fetch (`${BASE_URL}diets`);
        let data = await response.json();
        return (
            dispatch({
                type: GET_DIETS,
                payload: data
            })
        )
    }
}


export const filterFoodsByDiets = (diet) =>{
    return {
        type: FILTER_FOODS_BY_DIETS,
        payload: diet
    }
}
export const orderFoods = (order) => {
    return {
        type: ORDER_FOODS,
        payload: order
    }
}
export const orderHealthScore = (order) => {
    return {
        type: ORDER_HEALTHSCORE,
        payload: order
    }
}
export const searchFoodByName = (food) => {
    return {
        type: SEARCH_FOOD_BY_NAME,
        payload: food
    }
}

export const selectPage = (page) => {
    return {
        type: SELECT_PAGE,
        payload: page
    }
}

export const clearState = () => {
    return {
      type: CLEAR_STATE
    };
  };