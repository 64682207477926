import { applyMiddleware,createStore ,combineReducers } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import foodReducer from './foodReducer.js';
import authReducer from './authReducer.js';


const rootReducer = combineReducers({
    foodReducer: foodReducer,
    authReducer: authReducer
  });
const store = createStore(rootReducer,composeWithDevTools(applyMiddleware(thunk)));

export default store;