import React from 'react';
import { Link } from 'react-router-dom'
// import imgPrueba from '../imgs/fondo-food.jpg'

const Card = (props) => {
    let { id, image, name, diets, healthScore }= props;
    // console.log(props)
    return(
        <div className='Card' >
            <div className="Card__item">
                <Link to={`/recipes/${id}`}>
                    <div className="Card__img">
                        <img src={`${image}`} alt="RECIPE" />
                        <span>{healthScore}</span>
                    </div>
                    <div className="Card__title">
                        <h2 title={name}>{name.substring(0,35)}...</h2>
                    </div>
                    <div className="Card__description">
                        {   
                            //Recorremos todas las dietas de la receta y la mostramos
                            diets.map((t,i)=>(
                                <p key={i} className={t.replace(' ','_').replace(' ','_')}>{t}</p>
                            ))
                        }
                        {/* <p>VEGAN</p>
                        <p>PESCATARIAN</p> */}
                    </div>
                </Link>
            </div>
        </div>
    )
}
export default Card;