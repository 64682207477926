import React from 'react';

//importando otros componentes
import SearchBar from './SearchBar.jsx'
import Filters from './Filters.jsx'

const Aside = (props) => {
    const { handleShowChange } = props;
    return(
        <div className="Aside">
            <SearchBar/>
            <Filters 
                // handleFilterDietsChange={handleFilterDietsChange}
                // handleOrderChange={handleOrderChange}
                handleShowChange={handleShowChange}
             />
        </div>
    )
}
export default Aside;