import { 
    GET_ALL_FOODS, 
    FILTER_FOODS_BY_DIETS, 
    ORDER_FOODS,
    SEARCH_FOOD_BY_NAME,
    GET_FOOD_BY_ID,
    CLEAR_STATE,
    CREATE_FOOD,
    GET_DIETS,
    ORDER_HEALTHSCORE
    // SELECT_PAGE
} from './actions.js';
let initialState = {
    allFoods: [],
    filteredFoods:[],
    onlyFood:[]
    // limitPage: 9,
    // pages: 0,
    // offset: 0,
    // currentPage:  0,
}
const foodReducer = ( state = initialState, action) => {
    switch( action.type ){
        case GET_ALL_FOODS:
            return{
                ...state,
                allFoods: action.payload,//este estado sera el que se modificaara y se enviara a los componente
                filteredFoods: action.payload,//este estado nos servira para mantener todos las recetas intactas sin alteralas para hacer el filtrado
                // pages: Math.ceil(action.payload.length / state.limitPage),
                // offset: state.currentPage * state.limitPage,  
            }
        case FILTER_FOODS_BY_DIETS:
            console.log(state.allFoods.length )
            return{
                ...state,
                allFoods: [...state.filteredFoods.filter(food => (action.payload === 'all') ? state.filteredFoods : food.diets.includes(action.payload))],
                // pages: [...state.filteredFoods.filter(food => (action.payload === 'all') ? state.filteredFoods : food.diets.includes(action.payload))].length/ state.limitPage,
                // offset: state.currentPage * state.limitPage

                
            }
        case ORDER_FOODS:
            // console.log(action)
            return{
                ...state, 
                allFoods: [...state.allFoods.sort((a,b) =>{
                    const isReversed = (action.payload === 'Ascendente')?1:-1;
                    return isReversed * (a.id - b.id)
                })]

            }
            
        case ORDER_HEALTHSCORE:
            // console.log(action)
            return{
                ...state, 
                allFoods: [...state.allFoods.sort((a,b) =>{
                    const isReversed = (action.payload === 'Ascendente')?1:-1;
                    return isReversed * (a.healthScore - b.healthScore)
                })]

            }
        case SEARCH_FOOD_BY_NAME:
            return{
                ...state, 
                allFoods: [...state.filteredFoods.filter( food => ((food.name).toUpperCase()).includes(action.payload))]
            }

        case GET_FOOD_BY_ID:
            return{
                ...state, 
                onlyFood:[action.payload]
            }
        case CLEAR_STATE:
            return initialState;
            
        case CREATE_FOOD:
            return{
                ...state     
            }
        case GET_DIETS:
            return{
                    ...state,
                    diets:action.payload,
                }
        // case SELECT_PAGE:
        //         return {
        //             ...state,
        //         filteredFoods: [...state.filteredFoods],
        //         allFoods: [...state.filteredFoods.splice((action.payload * state.limitPage),state.limitPage)],
        //         currentPage:  action.payload,
        //         offset: (action.payload) * state.limitPage,
        //     }
        default:
            return {...state};
    }
}
export default foodReducer;