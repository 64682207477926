import React,{ useState} from 'react';
import { useDispatch } from 'react-redux';
import { filterFoodsByDiets, orderFoods, orderHealthScore } from '../redux/actions.js';

const Filters = (props) => {

    const [ isDisabled, setIsDisabled ] = useState(false);   

    const dispatch = useDispatch();

    const handleOrderChange = (e) => {
        dispatch(orderFoods(e.target.value))
        setIsDisabled(true)
    }
    const handleFilterDietsChange = (e) => {
        dispatch(filterFoodsByDiets(e.target.value))    
        setIsDisabled(true)
    }
    
    const handleOrderHealthScoreChange = (e) => {
        dispatch(orderHealthScore(e.target.value))    
        setIsDisabled(true)
    }


    return(
        <div className="Filters">
            <div className='Filters__item'>
                <label htmlFor="cmbOrder">Order foods</label>
                <select className="selectForm" name="cmbOrder" id="cmbOrder" onChange = {handleOrderChange}>
                    <option defaultValue="Order" disabled={isDisabled}>Order</option>
                    <option value="Ascendente">Ascendente</option>
                    <option value="Descendente">Descendente</option>
                </select>
            </div>
            <div className='Filters__item'>
                <label htmlFor="cmbFilterDiets">Filter for diets</label>
                <select className="selectForm" name="cmbFilterDiets" id="cmbFilterDiets" onChange = {handleFilterDietsChange} >
                    <option defaultValue="Diets" disabled={isDisabled}>Diets</option>
                    <option value="all">todos</option>
                    <option value="Gluten free">Gluten free</option>
                    <option value="dairy free">dairy free</option>
                    <option value="lacto ovo vegetarian">lacto ovo vegetarian</option>
                    <option value="vegan">vegan</option>
                    <option value="paleolithic">paleolithic</option>
                    <option value="primal">primal</option>
                    <option value="whole 30">whole 30</option> 
                    <option value="pescatarian">pescatarian</option>
                    <option value="ketogenic">ketogenic</option>
                    <option value="fodmap friendly">fodmap friendly</option>
                </select>
            </div>
            <div className="Filters__item">
                <label htmlFor="cmbFilterPerPage">Filter for page</label>
                <select name="cmbFilterPerPage" id="cmbFilterPerPage" onChange={props.handleShowChange} className="selectForm">
                    <option value="9">9</option>
                    <option value="18">18</option>
                    <option value="all">All</option>
                </select>
            </div>
            <div className="Filters__item">
                <label htmlFor="cmbFilterHealthScore" className='labelHealthScore'>Filter HealthScore</label>
                <select name="cmbFilterHealthScore" id="cmbFilterHealthScore" onChange={handleOrderHealthScoreChange} className="selectForm">
                    <option defaultValue="Order" disabled={isDisabled}>Order</option>
                    <option value="Ascendente">Ascendente</option>
                    <option value="Descendente">Descendente</option>
                </select>
            </div>
        </div>
    )
}
export default Filters;