
const regex = /^[a-zA-Z\s]+$/; //Valida solo texto
const regexEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;//Valida solo correo


const validateFood = (inputs) => {
    // console.log(inputs)
    let errors = {}
    //array para indicar campos qeu no seran obligatorios
    let arrNoRequired = ['healthScore','step','image']
    if(!validateEmptyForm(errors,inputs,arrNoRequired)){
        return false;
    }
    if(!regex.test(inputs.name)){
        errors.name = 'Only text'
    }
    if(inputs.healthScore==='0'){
        errors.healthScore = `Cannot have a healthScore 0`
    }
    return errors
}
const validateLogin = (inputs) => {
    let errors = {}
    let arrNoRequired = []
    if(!validateEmptyForm(errors,inputs,arrNoRequired)){
        return false;
    }
    if(!regexEmail.test(inputs.username)){
        errors.username = 'Only email'
    }
    return errors
    
}
export { 
    validateFood,
    validateLogin
 };

//Funcion para recorrer todos los campos del formulario en objeto para validarlos si estan vacios
const validateEmptyForm = (errors,inputs,arrNoRequired) => {
    for(let prop in inputs){
        // console.log(prop)
        if(!arrNoRequired.includes(prop)){

            if(inputs[prop]===''){
                errors[prop] = `Empty field: ${prop}`
            }
        }
    }
    return inputs
}