import {
    LOGIN_USER,
    LOGOUT_USER
} from './authActions.js'

const initialState = {
    access:false,
    response:[]
}

const authReducer = ( state = initialState, action) => {
    switch( action.type ){
        case LOGIN_USER:
           
            return{
                ...state,
                response: action.payload,
                access: (action.payload.error)?false:true
            }
        case LOGOUT_USER:
            
            return{
                ...state,
                access: false
            }
        default:
            return {...state};
    }
}

export default authReducer;