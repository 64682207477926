import React from 'react';

//Importando otros componentes
import Nav from './Nav.jsx'
const About = () => {
    return(
        <div>
            <Nav/>

            <div className='Detail'>
                <div className="Detail__title">
                    <h2>The only food API you'll ever need.</h2>
                </div>
                <div className="Detail__article">
                    <div className="Detail__content">
                    <p>Our knowledge engineers spent years crafting our complex food ontology, which allows us to understand the relationships between ingredients, recipes, nutrition, allergens, and more.</p>

                    <p>We understand "nut free" muffins can't contain pecans (even if the recipe doesn't mention "nuts" anywhere!) and we automatically determine that a recipe with Worcestershire sauce isn't vegetarian (we're looking at you, anchovies.)</p>
                    </div>
                </div>
             
                
                
            </div>

        </div>
    )
}
export default About;