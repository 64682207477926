import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getFoodById,clearState } from '../redux/actions.js';

//importando componentes
import Nav from './Nav.jsx';

const Detail = (props) => {
    //Recibimos por parametro el id del Card
    const { detailId } = useParams();
    const dispatch = useDispatch();
    //Almacenamos el estado global del detalle de la receta en la constante food
    const food = useSelector(state => state.foodReducer.onlyFood)
    useEffect(()=>{
        setTimeout(()=>{
            dispatch(getFoodById(detailId))
        },500)
        return ()=>{
            dispatch(clearState());
        }
        
    },[detailId,dispatch])
    
    const navigate = useNavigate();
    const returnHome = (e) => {
        e.preventDefault()
        return navigate('/home')
    }
   
    return(
    
    
        <>
            <Nav/>
            {   (food[0])
                ?   <div className='Detail'>
                        <div className="Detail__title">
                            <a className="btnBack" onClick={returnHome} href=" ">&#9668;</a>
                            <h2>{food[0].name}</h2>
                        </div>
                        <div className="Detail__article">
                            <div className="Detail__img">
                                <img src={food[0].image} alt={food[0].name} />
                            </div>
                            <div className="Detail__content">
                                    <p>HealthScore: {food[0].healthScore}</p>
                                    <p className='Card__description'>
                                        Diets:  
                                        {food[0].diets?.map((diet,i)=>(
                                            <span key={i} className={diet.name.replace(' ','_').replace(' ','_')}>{diet.name}</span>
                                        ))}
                                    </p>
                                    <h3 className="Detail__subtitle"><b>Summary:</b></h3>
                                    <p dangerouslySetInnerHTML={{ __html: food[0].summary }}></p>
                            </div>
                        </div>
                        <div className="Detail__food">
                            <div>
                                <h3 className="Detail__subtitle"><b>Steps</b></h3>   
                                <div className="Detail__steps">{food[0].steps?.map((step,i)=>(
                                        <p className="" key={i}>{step.number}: {step.step}</p>
                                    ))}
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>: 'CARGANDO...'  
                
            }     
        </>    
    )
    
    
}
export default Detail;