import React from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { selectPage } from '../redux/actions.js';


const Pagination = (props) => {

    // const pages = useSelector(state => state.pages);
    // const dispatch = useDispatch();
    const { pages, handlePage, currentPage, handlePrev, handleNext } = props;
    let contador = 0;
    let pagesView = [];
    while(pages > contador){
        pagesView.push(contador)
        contador++
    }

    
    return (
        <div className="Pagination">
            <button onClick={handlePrev}>PREV</button>
            <ul className="pages" >
                {
                    pagesView?.map((p,i)=>(
                        <li className={`pages__item ${(currentPage===p)?'pageActivate':''}`} key={i} onClick={()=>handlePage(p)}>{p+1}</li>
                        // <li className={`pages__item ${(currentPage===p)?'pageActivate':''}`} key={i} onClick={()=>handlePage(p)}>{p+1}</li>
                    ))
                }
            </ul>
            <button onClick={handleNext}>NEXT</button>
            
        </div>
    );
}
export default Pagination;