import React,{ useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDiets, createFood } from '../redux/actions.js'
import Nav from './Nav.jsx';
import { validateFood}  from './validation.js'

const Form = () => {

   const dispatch = useDispatch();
   const diets = useSelector(state => state.foodReducer.diets)

   useEffect(() => {
       dispatch(getDiets())
   }, [dispatch])

//    console.log(diets)

   const [input, setInput ] = useState({
       name:'',
       summary:'',
       image:'',
       healthScore:50,
       steps:[],
       diets:[],
       step:'',
       createdInDB:true
   })
   const [fileImage, setFileImage] = useState(null)

   const [error, setError ] = useState({
    name:'',
    summary:'',
    image:'',
    healthScore:'',
    steps:[],
    diets:[],
    step:'',
})

   const handleSubmit = (e) => {
       e.preventDefault();
       let arrayErrors = Object.values(error)
      
       if(arrayErrors.length >= 1){
           window.alert('Debes corregir los errores')
        }else{

            const formData = new FormData();
            formData.append('file',fileImage)
            formData.append('inputs',JSON.stringify(input))
            // dispatch(createFood(formData))
            dispatch(createFood(formData))

            setInput({
                name:'',
                summary:'',
                image:'',
                healthScore:50,
                steps:[],
                diets:[],
                step:'',
                createdInDB:true
            })

            setError({
                name:'',
                summary:'',
                image:'',
                healthScore:'',
                steps:[],
                diets:[],
                step:'',
            })
            
        }
   }
   const handleInputChange = (e) => {
       e.preventDefault()

       setInput({...input,
           [e.target.name]:e.target.value,
       })
      
       setError(validateFood({...input,
        [e.target.name]:e.target.value,
       }))
       
   }

   const handleFileChange = (e) => {
        setFileImage(e.target.files[0])
   }
   const handleSelectChange = (e) => {
       e.preventDefault()
       const findDiet = (input.diets.find(diet=>diet.name===(e.target.value)));
       !findDiet ? 
       setInput({...input,
           diets:[...input.diets,{
               name: e.target.value
           }],
       }) : alert(`Diet ${e.target.value} already exists `)
       e.target.value = 'selectDiet';
   }

    const onClose = (name,type) => {
        // console.log('hola')
        // console.log(name)
        (type === 'diets')
        ?   setInput({...input,
                diets:[...input.diets.filter(diet=>diet.name!==name)],
            })
        :   setInput({...input,
                steps:[...input.steps.filter(diet=>diet.name!==name)],
            })
    }
    const addStep = (e) => {
        
        e.preventDefault()
        // console.log('agregafdo')
        setInput({...input,
            steps:[...input.steps,{
                name: input.step
            }],
            step:''
        })
    }
   return (
       <>
        <Nav/>
        <div className="form">
            <h2>CREATE FOOD</h2>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="form__input">
                    <label htmlFor="name">Name: </label>
                    <input type="text" name="name" id="name" value={input.name} onChange={handleInputChange}/>
                </div>
                    {error.name && <p className="danger">{error.name}</p>}
                <div className="form__input">
                    <label htmlFor="summary">Summary: </label>
                    <textarea name="summary" id="summary" cols="30" rows="4"  value={input.summary} onChange={handleInputChange}></textarea>
                </div>
                    {error.summary && <p className="danger">{error.summary}</p>}
                <div className="form__input">
                    <label htmlFor="image">Image: </label>
                    <input type="file" name="image" id="image" value={input.image} onChange={handleFileChange}/>
                </div>
                    {error.image && <p className="danger">{error.image}</p>}
                <div className="form__input">
                    <label htmlFor="healthScore">HealthScore: </label>
                    <input type="range" name="healthScore" id="healthScore" value={input.healthScore} onChange={handleInputChange}/>
                </div>
                    {error.healthScore && <p className="danger">{error.healthScore}</p>}
                <div className="form__input">
                    <label htmlFor="step">Steps: </label>
                    <button className="btn btnSmall btnGreen btnAdd" onClick={addStep}>+</button>
                    <input type="text" name="step" id="step" onChange={handleInputChange} value={input.step}/>
                </div>
                    {error.step && <p className="danger">{error.step}</p>}
                <div className="form__input steps">
                        {
                            input.steps?.map((step,i)=>(
                                <p key={i}>{i+1}.- {step.name} <i onClick={() => onClose(step.name,'steps')}>x</i></p>
                            ))
                        }
                </div>
                
                <div className="form__input">
                    <label htmlFor="diet">Diet</label>
                    <select name="diet" id="diet" onChange={handleSelectChange}>
                        <option value="selectDiet">Select diets</option>
                        { 
                            diets?.map(diet => (
                                <option value={diet.name} key={diet.id}>{diet.name}</option>
                            ))
                        }
                    </select>
                    
                </div>
                <div className="form__input">
                    <div className="form__types">
                        {
                        input.diets?.map( (t,i) => (
                            <span key={i} className={t.name.replace(' ','_').replace(' ','_')}>{t.name} <i onClick={() => onClose(t.name,'diets')}>x</i></span>
                        ))
                        }
                    </div>
                </div>
                <button type="submit" className='btn btnLarge btnRed btnBlock'>CREATE FOOD</button>
            </form>
        </div>
       </>
   )
}
export default Form;