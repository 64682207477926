export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
// const BASE_URL = 'http://localhost:3001/';
const  BASE_URL  = process.env.REACT_APP_BASE_URL;
export const loginUser = (payload) => {
    return async (dispatch) => {
        let myHeaders = new Headers({
            'Content-Type': 'application/json'
        });
        let myInit = {
            method:'POST',
            body: JSON.stringify(payload),
            // body: payload,
            headers: myHeaders
        }
        let response = await fetch(`${BASE_URL}users/login`, myInit);
        let data = await response.json()
        // if(data.error)return window.alert(data.error);
        // else window.alert(data.message)

        return (dispatch({
            type: LOGIN_USER,
            payload: data
        }))
    }

}


export const logoutUser = (payload) =>{
    return {
        type: LOGOUT_USER,
        payload: payload
    }
}