import React,{ useState}  from 'react';
import { useDispatch } from 'react-redux';
import { searchFoodByName, getAllFoods } from '../redux/actions';

const SearchBar = (props) => {

    const [ food, setFood ] = useState();
    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        e.preventDefault()
        setFood(e.target.value)
    }
    const onSearchFood = (e) => {
        e.preventDefault()
        dispatch(searchFoodByName(food.toUpperCase()))
        setFood('')
    }
    const onGetAllFoods = (e) => {
        e.preventDefault()
        dispatch(getAllFoods()) 
        setFood('')
    }
    return (
        <div className="searchBar">
            <form action="">
                <label htmlFor="food">Search food</label>
                <input type="search" name="food" id="food"  className='inputForm' value={food} onChange={handleInputChange}/>
                <button className='btn btnSmall btnOutlineGold' onClick={onGetAllFoods}>All</button>
                <button className='btn btnSmall btnGold' onClick={onSearchFood}>Search</button>
            </form>
        </div>
    )
}

export default SearchBar;