import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//importando actions creators
import { getAllFoods,clearState } from '../redux/actions.js';
//Importando componentes
import Cards from './Cards.jsx'
import Nav from './Nav.jsx'
import Aside from './Aside.jsx'
import Pagination from './Pagination.jsx'

const Home = () => {
    
    const dispatch = useDispatch();
    const allFoods = useSelector(state => state.foodReducer.allFoods)
    const navigate = useNavigate();
    const accessAuth = useSelector(state=>state.authReducer.access);
    // console.log(allFoods)
    useEffect(() => {
        dispatch(getAllFoods())
        //limpiamos el estado global cuadno desmontamos el componente
        return ()=>{
            dispatch(clearState());
        }
    },[dispatch])

    const [ allFoodsPage, setAllFoodPage ] = useState({
        // foods: [],
        foodsPerPage: [],
        currentPage: 0,
        limitPage: 9
    });

    // const limitPage = 9;
    const pages = allFoods.length / allFoodsPage.limitPage;

    //almacenamos el estado global en un estado local limitando incialmente a 9 por pagina
    useEffect(() => {
        setAllFoodPage({
            // foods: allFoods,
            foodsPerPage:  [...allFoods].splice(0, 9),
            currentPage: 0,
            limitPage: 9
            
        })
        
    },[allFoods])

    const handlePage = (currentPage) => {
        const offsetPage = currentPage * allFoodsPage.limitPage;
        setAllFoodPage({
            foodsPerPage: [...allFoods].splice(offsetPage,allFoodsPage.limitPage),
            currentPage:  currentPage,
            limitPage: allFoodsPage.limitPage
        })
    }

    const handlePrev = () => {
        const prevPage = allFoodsPage.currentPage - 1;
        const offsetPage = prevPage * allFoodsPage.limitPage;
        if(prevPage < 0 ) return;
        setAllFoodPage({
            foodsPerPage:[...allFoods].splice(offsetPage,allFoodsPage.limitPage),
            currentPage:  prevPage,
            limitPage: allFoodsPage.limitPage
        })
    }
    const handleNext = () => {
        const nextPage = allFoodsPage.currentPage + 1;
        const offsetPage = nextPage * allFoodsPage.limitPage;
        if( offsetPage > allFoods.length ) return;
        setAllFoodPage({
            foodsPerPage:[...allFoods].splice(offsetPage,allFoodsPage.limitPage),
            currentPage:  nextPage,
            limitPage: allFoodsPage.limitPage
        })
        
    }

    const handleShowChange = (e) => {
        const limitPage = e.target.value;
        const offsetPage = allFoodsPage.currentPage * limitPage;

        setAllFoodPage({
            foodsPerPage: (limitPage === 'all')?[...allFoods]:[...allFoods].splice(offsetPage,parseInt(limitPage)),
            currentPage:  allFoodsPage.currentPage,
            limitPage: (limitPage === 'all') ? 100 : parseInt(limitPage)
        })
    }

    useEffect(() => {
        !accessAuth && navigate('/');
      }, [accessAuth,navigate]);
    return(
        <div className="Home">
            <Nav/>
            <div className='Main'>
                <Aside  
                    handleShowChange={handleShowChange}
                />
                <div className='Content'>
                    {allFoods.length && <Pagination 
                        pages={pages} 
                        handlePage={handlePage} 
                        currentPage={allFoodsPage.currentPage}
                        handlePrev={handlePrev}
                        handleNext={handleNext}
                       
                    />} 
                    
                    {allFoods.length && <Cards allFoods={allFoodsPage.foodsPerPage} />}
                </div>
            </div>
        </div>
    )
}
export default Home;